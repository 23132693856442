export enum TopSearchDropdownItemMode {
	LOCATIONS = 'locations',
	DATE_RANGE = 'offersDateRange',
	STOPS = 'stops',
	PARTICIPANTS = 'participants',
	KINDS = 'kinds',
	BOARDS = 'boards',
	TAGS = 'tags',
	CONVENIENCES = 'conveniences',
	NIGHTS = 'nights',
}

enum ExtendedTopSearchDropdownItemMode {
	ADULTS = 'adults',
	CHILDREN = 'children',
}

export enum ExtendedTopSearchItemModeWithSearchInput {
	SEARCH = 'search',
}

const CombinedTopSearchDropdownItemMode = {
	...TopSearchDropdownItemMode,
	...ExtendedTopSearchDropdownItemMode,
	...ExtendedTopSearchItemModeWithSearchInput,
} as const;

const { ...CombinedTopSearchDropdownItemModeWithoutParticipants } = CombinedTopSearchDropdownItemMode;

export const OfferListHeaderItemMode = CombinedTopSearchDropdownItemModeWithoutParticipants;

export const MultiChoiceModes: TopSearchDropdownItemMode[] = [
	TopSearchDropdownItemMode.LOCATIONS,
	TopSearchDropdownItemMode.STOPS,
	TopSearchDropdownItemMode.KINDS,
	TopSearchDropdownItemMode.BOARDS,
	TopSearchDropdownItemMode.TAGS,
	TopSearchDropdownItemMode.CONVENIENCES,
];

export const CounteringModes: TopSearchDropdownItemMode[] = [
	TopSearchDropdownItemMode.PARTICIPANTS,
	TopSearchDropdownItemMode.NIGHTS,
];
export enum DropdownType {
	MULTI = 'multi',
	COUNTER = 'counter',
	DATE = 'date',
}

export enum Participant {
	ADULTS = 'Dorośli',
	CHILDREN = 'Dzieci (0-12 lat)',
}

export enum Nights {
	FROM = 'Od',
	TO = 'Do',
}

export interface Row {
	rowName: Participant | Nights;
	options: number[];
}

export interface RowValue {
	value: number;
	type: Participant | Nights;
}
