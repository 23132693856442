/**
 * Normalizes string to be URL-friendly by converting it to lowercase,
 * replacing specific Polish characters, removing special characters,
 * and replacing spaces with hyphens.
 *
 * @param {string} str - The input string to normalize.
 * @returns {string} The normalized URL-friendly string.
 */
export const normalizeStringToUrl = (str: string): string =>
	str
		.toLocaleLowerCase()
		.replace(/ą/g, 'a')
		.replace(/ę/g, 'e')
		.replace(/ś/g, 's')
		.replace(/ć/g, 'c')
		.replace(/ż/g, 'z')
		.replace(/ź/g, 'z')
		.replace(/ł/g, 'l')
		.replace(/ó/g, 'o')
		.replace(/ń/g, 'n')
		.replace(/[^a-zA-Z0-9\ ]+/g, '')
		.trim()
		.replace(/ /g, '-');
